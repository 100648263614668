import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';


interface IData {
  id: number;
  color: string;
  email: string;
  name: string;
  primary_interest: string;
}

interface IProduct {
  name: string;
  price: number;
  imageUrl: string;
  id: number;
}

const generateProducts = (): IProduct[] => {
  const tshirtUrls: string[] = [
    'https://images.pexels.com/photos/1656684/pexels-photo-1656684.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/428338/pexels-photo-428338.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/991509/pexels-photo-991509.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/1261422/pexels-photo-1261422.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/1861907/pexels-photo-1861907.jpeg?auto=compress&cs=tinysrgb&w=600',
  ];

  const jeansUrls: string[] = [
    'https://images.pexels.com/photos/1346187/pexels-photo-1346187.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/19561004/pexels-photo-19561004/free-photo-of-young-man-in-denim-overalls-standing-next-to-a-tree.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/5707733/pexels-photo-5707733.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/5339952/pexels-photo-5339952.jpeg?auto=compress&cs=tinysrgb&w=600',
  ];

  const jacketUrls: string[] = [
    'https://images.pexels.com/photos/977796/pexels-photo-977796.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://t4.ftcdn.net/jpg/02/36/49/63/360_F_236496348_Pejh1LNDLjsmyDYngfli9n8SNRAARevg.webp',
    'https://media.gettyimages.com/id/904659086/photo/young-man-hiking-in-rain-with-waterproof-jacket.jpg?s=612x612&w=0&k=20&c=4KEpu4KhW9D_Cyh7dHp5KW-6VpSSYnEaXOfIo5aJrus=',
    'https://media.gettyimages.com/id/172263787/photo/lettermans-jacket.jpg?s=612x612&w=0&k=20&c=GltTrA0QWgg_15Wvi87agM1JYk5h4HQINeMZnMR5fB4=',
    'https://media.gettyimages.com/id/157692462/photo/front-of-black-leather-jacket-isolated-on-white-w-clipping-path.jpg?s=612x612&w=0&k=20&c=Dt9BAaU1O38SuIQEpoJoOQu0OcX-9za-DRD1MfIbOnw=',
    'https://media.gettyimages.com/id/640964801/photo/rear-view-of-auto-mechanic-student-wearing-reflective-jacket-in-workshop.jpg?s=612x612&w=0&k=20&c=9z-FrgNbT-UyvtmgdDDTZSl9dw7nPcEACliGvZLrh6U=',
    'https://media.gettyimages.com/id/185000949/photo/modern-green-ski-jacket-isolated-on-white-background-studio-shot.jpg?s=612x612&w=0&k=20&c=gUEToj8_osvoRa6CM3AmQ5WWrjM4zjehbU_oVE7LQg4=',
  ];

  const sneakerUrls: string[] = [
    'https://media.gettyimages.com/id/171224469/photo/canvas-shoes.jpg?s=612x612&w=0&k=20&c=oD5A61xxgna-0WNafNcZxySSwCiEnUCs5wiDJVfb2tQ=',
    'https://media.gettyimages.com/id/1169282638/photo/running-shoe-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=MGCTfacY0A9DLHoJHNol-PZG9qvpr4Phu-eODzXbB_0=',
    'https://media.gettyimages.com/id/1062984272/photo/gray-tennis-shoe-running-exercise-sneaker-copy-space.jpg?s=612x612&w=0&k=20&c=XooM9Q3kVUuPlDAy1mEZtvCcj9NY2NXU7sA76uTNyJU=',
    'https://media.gettyimages.com/id/183958953/photo/mens-trainers.jpg?s=612x612&w=0&k=20&c=WYjzqzKvGynoUkXRpFfB0hyLGa8ZOM0cwgQ3v9ajN2s=',
  ];

  const hatUrls: string[] = [
    'https://media.gettyimages.com/id/171115334/photo/green-cap.jpg?s=612x612&w=0&k=20&c=_aNcWCOEpHJjCcNKDvtI0nin819zGMgrb_pVLEiLVrI=',
    'https://media.gettyimages.com/id/1034199106/photo/santa-hat-on-white.jpg?s=612x612&w=0&k=20&c=yW_mm9rorivilix5S9mhD4Rwk-ufO_23dAUi5y1w8wg=',
    'https://media.gettyimages.com/id/185248743/photo/baseball-cap.jpg?s=612x612&w=0&k=20&c=FH8yaa6BQ8p9g4RxerwWHwSw2uauaDPlS2TqKtm2CYw=',
    'https://media.gettyimages.com/id/135326211/photo/cowboy-hat-close-up.jpg?s=612x612&w=0&k=20&c=04OSinvNYrsWT8sPW47-gqVOaGjwYnJvBXar9MRm9vc=',
  ];

  const allProductUrls: string[][] = [
    tshirtUrls,
    jeansUrls,
    jacketUrls,
    sneakerUrls,
    hatUrls,
  ];

  const getCategoryName = (categoryIndex: number): string => {
    switch (categoryIndex) {
      case 0:
        return 'T-shirts';
      case 1:
        return 'Jeans';
      case 2:
        return 'Jackets';
      case 3:
        return 'Sneakers';
      case 4:
        return 'Hats';
      default:
        return '';
    }
  };

  const brands: string[] = ['Luxury', 'Athletic', 'Comfort Wear', 'Formal', 'Slim Fit'];

  const generatedProducts: IProduct[] = [];

  let counter = 1; // Add a counter variable to generate unique ids for each product

  allProductUrls.forEach((productUrls: string[], index: number) => {
    productUrls.forEach((url: string, i: number) => {
      const brandIndex: number = Math.floor(Math.random() * brands.length);
      const brand: string = brands[brandIndex];
      const categoryName: string = getCategoryName(index);
      const id: number = counter

      generatedProducts.push({
        name: `${categoryName} - ${brand}`,
        price: Math.floor(Math.random() * 100) + 1,
        imageUrl: url,
        id: id
      });

      counter++;
    });
  });

  return generatedProducts
};

const sortProducts = (products: IProduct[], data: IData): IProduct[] => {
  const sortedProducts: IProduct[] = products.sort((a, b) => {
    const aIncludesPrimaryInterest = a.name.toLowerCase().includes(data.primary_interest.toLowerCase().replace(/s$/, ''));
    const bIncludesPrimaryInterest = b.name.toLowerCase().includes(data.primary_interest.toLowerCase().replace(/s$/, ''));

    if (aIncludesPrimaryInterest && !bIncludesPrimaryInterest) {
      return -1;
    } else if (!aIncludesPrimaryInterest && bIncludesPrimaryInterest) {
      return 1;
    }

    return 0;
  });

  return sortedProducts;
};

function ApparelApp(): JSX.Element {
  const [userId, setUserId] = useState<string>('1');
  const [data, setData] = useState<IData | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isProductsFetched, setIsProductsFetched] = useState(false);

  const [renderedProducts, setRenderedProducts] = useState<JSX.Element[]>([]);

  const fetchAndSetData = async (): Promise<void> => {
    try {
      const response = await axios.post<{ data: IData }>(
        `https://api.entity-api-demo.getcensus.com`,
        { record_id: userId, entity_type: "apparel" }
      );
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderProducts = (sortedProducts: IProduct[]): JSX.Element[] => {
    return sortedProducts.map((product: IProduct, index: number) => (
      <ProductTile key={product.id}>
        <ProductImage src={product.imageUrl} alt={product.name} />
        <div>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>${product.price}</ProductPrice>
        </div>
      </ProductTile>
    ));
  };

  useEffect(() => {
    const fetchDataAndWait = async () => {
      await fetchAndSetData();
      setIsDataFetched(true);
    };
  
    setIsDataFetched(false);
    fetchDataAndWait();
  }, [userId]);
  
  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      setProducts(generateProducts());
      setIsProductsFetched(true);
    };
  
    fetchProducts();
  }, []);

  useEffect(() => {
    const sortAlreadyFetchedProducts = async (): Promise<void> => {
      if (isDataFetched && isProductsFetched) {
        const sortedReturnedProducts = sortProducts(products, data!)
        setRenderedProducts(renderProducts(sortedReturnedProducts));
      }
    }

    sortAlreadyFetchedProducts();
  }, [isDataFetched, isProductsFetched, data, products]);

  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setUserId(event.target.value);
  };
  
  const renderContent = (): JSX.Element => {
    if (!data) {
      return <div>Loading...</div>;
    }

    return (
      <AppContainer data={data}>
        <h2>Logged in user: {data.name}</h2>
        <h3>Email: {data.email}</h3>
        <select value={userId} onChange={handleUserChange}>
          <option value="1">Alexa Smith</option>
          <option value="2">Brandon Jones</option>
          <option value="3">Catherine Lee</option>
          <option value="4">Daniel Garcia</option>
          <option value="5">Emily Zhang</option>
        </select>
        <h3>Primary Interest: {data.primary_interest}</h3>
        <ProductContainer>{renderedProducts}</ProductContainer>
      </AppContainer>
    );
  };

  return renderContent();
}

const AppContainer = styled.div<{ data: IData }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: ${props => props.data.color};
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr)); // Updated to 5 columns
  grid-gap: 20px;
  margin-top: 20px;
  padding: 20px;
`;
const ProductTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const ProductName = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const ProductPrice = styled.p`
  margin: 5px 0;
  font-size: 16px;
`;



export default ApparelApp;
