import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';


interface IData {
  color: string;
  org_id: string;
  org_name: string;
  primary_interest: string;
}

interface IProduct {
  name: string;
  price: number;
  imageUrl: string;
  id: number;
}

const generateProducts = (): IProduct[] => {
  const laptopUrls: string[] = [
    'https://i.dell.com/is/image/DellContent//content/dam/ss2/product-images/dell-client-products/notebooks/latitude-notebooks/14-3420/media-gallery/peripherals_laptop_latitude_3420nt_gallery_3.psd?fmt=pjpg&pscan=auto&scl=1&wid=3635&hei=2548&qlt=100,1&resMode=sharp2&size=3635,2548&chrss=full&imwidth=5000',
    'https://i.dell.com/is/image/DellContent//content/dam/ss2/product-images/dell-client-products/notebooks/latitude-notebooks/14-3420/media-gallery/peripherals_laptop_latitude_3420nt_gallery_3.psd?fmt=pjpg&pscan=auto&scl=1&wid=3635&hei=2548&qlt=100,1&resMode=sharp2&size=3635,2548&chrss=full&imwidth=5000',
    'https://cdn.thewirecutter.com/wp-content/media/2023/06/laptops-2048px-5607.jpg?auto=webp&quality=75&crop=1.91:1&width=1200',
    'https://cdn.thewirecutter.com/wp-content/media/2023/06/laptops-2048px-5607.jpg?auto=webp&quality=75&crop=1.91:1&width=1200',
    'https://cdn.thewirecutter.com/wp-content/media/2023/06/laptops-2048px-5607.jpg?auto=webp&quality=75&crop=1.91:1&width=1200',
  ];

  const cableUrls: string[] = [
    'https://resource.logitech.com/w_800,c_lpad,ar_4:3,q_auto,f_auto,dpr_1.0/d_transparent.gif/content/dam/logitech/en/products/video-conferencing/group-10m-extended-cable/gallery/vc-10m-cable-gallery-1.png?v=1',
    'https://resource.logitech.com/w_800,c_lpad,ar_4:3,q_auto,f_auto,dpr_1.0/d_transparent.gif/content/dam/logitech/en/products/video-conferencing/group-10m-extended-cable/gallery/vc-10m-cable-gallery-1.png?v=1',
    'https://resource.logitech.com/w_800,c_lpad,ar_4:3,q_auto,f_auto,dpr_1.0/d_transparent.gif/content/dam/logitech/en/products/video-conferencing/group-10m-extended-cable/gallery/vc-10m-cable-gallery-1.png?v=1',
    'https://resource.logitech.com/w_800,c_lpad,ar_4:3,q_auto,f_auto,dpr_1.0/d_transparent.gif/content/dam/logitech/en/products/video-conferencing/group-10m-extended-cable/gallery/vc-10m-cable-gallery-1.png?v=1',
  ];

  const routerUrls: string[] = [
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
    'https://dlcdnwebimgs.asus.com/gain/2f77c780-f669-4351-b5de-f5521ba5b3b1/',
  ];

  const monitorUrls: string[] = [
    'https://m.media-amazon.com/images/I/51v6h2TfPCL._AC_UF894,1000_QL80_.jpg',
    'https://m.media-amazon.com/images/I/51v6h2TfPCL._AC_UF894,1000_QL80_.jpg',
    'https://m.media-amazon.com/images/I/51v6h2TfPCL._AC_UF894,1000_QL80_.jpg',
    'https://m.media-amazon.com/images/I/51v6h2TfPCL._AC_UF894,1000_QL80_.jpg',
  ];

  const standingDeskUrls: string[] = [
    'https://t3.ftcdn.net/jpg/04/66/04/70/360_F_466047061_Wi4lERcxpVCORO3P7KcXevsxT6g8VHQG.jpg',
    'https://t3.ftcdn.net/jpg/04/66/04/70/360_F_466047061_Wi4lERcxpVCORO3P7KcXevsxT6g8VHQG.jpg',
    'https://t3.ftcdn.net/jpg/04/66/04/70/360_F_466047061_Wi4lERcxpVCORO3P7KcXevsxT6g8VHQG.jpg',
    'https://t3.ftcdn.net/jpg/04/66/04/70/360_F_466047061_Wi4lERcxpVCORO3P7KcXevsxT6g8VHQG.jpg',
  ];

  const allProductUrls: string[][] = [
    laptopUrls,
    cableUrls,
    routerUrls,
    monitorUrls,
    standingDeskUrls,
  ];

  const getCategoryName = (categoryIndex: number): string => {
    switch (categoryIndex) {
      case 0:
        return 'Laptop';
      case 1:
        return 'Cable';
      case 2:
        return 'Router';
      case 3:
        return 'Monitor';
      case 4:
        return 'Standing Desk';
      default:
        return '';
    }
  };

  const brands: string[] = ['Lenovo', 'Apple', 'Dell', 'HP', 'Samsung', 'Asus', 'Acer'];

  const generatedProducts: IProduct[] = [];

  let counter = 1; // Add a counter variable to generate unique ids for each product

  allProductUrls.forEach((productUrls: string[], index: number) => {
    productUrls.forEach((url: string, i: number) => {
      const brandIndex: number = Math.floor(Math.random() * brands.length);
      const brand: string = brands[brandIndex];
      const categoryName: string = getCategoryName(index);
      const id: number = counter

      generatedProducts.push({
        name: `${categoryName} - ${brand}`,
        price: Math.floor(Math.random() * 1000) + 1,
        imageUrl: url,
        id: id
      });

      counter++;
    });
  });

  return generatedProducts
};

const sortProducts = (products: IProduct[], data: IData): IProduct[] => {
  const sortedProducts: IProduct[] = products.sort((a, b) => {
    const aIncludesPrimaryInterest = a.name.toLowerCase().includes(data.primary_interest.toLowerCase().replace(/s$/, ''));
    const bIncludesPrimaryInterest = b.name.toLowerCase().includes(data.primary_interest.toLowerCase().replace(/s$/, ''));

    if (aIncludesPrimaryInterest && !bIncludesPrimaryInterest) {
      return -1;
    } else if (!aIncludesPrimaryInterest && bIncludesPrimaryInterest) {
      return 1;
    }

    return 0;
  });

  return sortedProducts;
};

function App(): JSX.Element {
  const [organizationId, setOrganizationId] = useState<string>('1');
  const [data, setData] = useState<IData | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isProductsFetched, setIsProductsFetched] = useState(false);

  const [renderedProducts, setRenderedProducts] = useState<JSX.Element[]>([]);

  const fetchAndSetData = async (): Promise<void> => {
    try {
      const response = await axios.post<{ data: IData }>(
        `https://api.entity-api-demo.getcensus.com`,
        { organization_id: organizationId }
      );
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const renderProducts = (sortedProducts: IProduct[]): JSX.Element[] => {
    return sortedProducts.map((product: IProduct, index: number) => (
      <ProductTile key={product.id}>
        <ProductImage src={product.imageUrl} alt={product.name} />
        <div>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>${product.price}</ProductPrice>
        </div>
      </ProductTile>
    ));
  };

  useEffect(() => {
    const fetchDataAndWait = async () => {
      await fetchAndSetData();
      setIsDataFetched(true);
    };
  
    setIsDataFetched(false);
    fetchDataAndWait();
  }, [organizationId]);
  
  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      setProducts(generateProducts());
      setIsProductsFetched(true);
    };
  
    fetchProducts();
  }, []);

  useEffect(() => {
    const sortAlreadyFetchedProducts = async (): Promise<void> => {
      if (isDataFetched && isProductsFetched) {
        const sortedReturnedProducts = sortProducts(products, data!)
        setRenderedProducts(renderProducts(sortedReturnedProducts));
      }
    }

    sortAlreadyFetchedProducts();
  }, [isDataFetched, isProductsFetched, data, products]);

  const handleOrganizationChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setOrganizationId(event.target.value);
  };
  
  const renderContent = (): JSX.Element => {
    if (!data) {
      return <div>Loading...</div>;
    }

    return (
      <AppContainer data={data}>
        <h2>{data.org_name}</h2>
        <select value={organizationId} onChange={handleOrganizationChange}>
          <option value="1">TechSolutions Inc.</option>
          <option value="2">NetworkTech Corp.</option>
          <option value="3">DataCom Enterprises</option>
          <option value="4">ITPro Solutions</option>
          <option value="5">ConnectTech Systems</option>
        </select>
        <h3>Primary Interest: {data.primary_interest}</h3>
        <ProductContainer>{renderedProducts}</ProductContainer>
      </AppContainer>
    );
  };

  return renderContent();
}

const AppContainer = styled.div<{ data: IData }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: ${props => props.data.color};
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr)); // Updated to 5 columns
  grid-gap: 20px;
  margin-top: 20px;
  padding: 20px;
`;
const ProductTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const ProductName = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const ProductPrice = styled.p`
  margin: 5px 0;
  font-size: 16px;
`;



export default App;
